import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import data from '../../data/data.json';
import './ProjectDetails.css';

function ProjectDetails() {
    const { id } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const project = data.find(proj => proj.id === parseInt(id, 10));
        setProject(project);
    }, [id]);

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div className="project-details">
            <h1>{project.title}</h1>
            <img
                src={project.image}
                alt={project.title}
                className={project.type.includes('Mobile') ? 'mobile' : ''}
            />
            <p>{project.longDescription}</p>

            {project.status === "unavailable" ? (
                <div className="project-unavailable">
                    <p>Ce projet est actuellement indisponible pour le téléchargement. Il sera bientôt possible de le tester.</p>
                </div>
            ) : (

                <>

            {/* Bouton Voir Projet */}
            {project.link ? (
                <a
                    className="btn-primaire"
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Voir Projet
                </a>
            ) : (
                <button className="btn-primaire btn-disabled" disabled>
                    Voir Projet
                </button>
            )}

            {/* Boutons de téléchargement */}
            {project.downloadLinks && (
    <div className="download-links">
        {project.downloadLinks.mac && (
            <a
                className="btn-download"
                href={project.downloadLinks.mac}
                target="_blank"
                rel="noopener noreferrer"
            >
                Télécharger pour Mac
            </a>
        )}
        {project.downloadLinks.windows && (
            <a
                className="btn-download"
                href={project.downloadLinks.windows}
                target="_blank"
                rel="noopener noreferrer"
            >
                Télécharger pour Windows
            </a>
        )}
    </div>
)}

            {project.codeSource && (
                <a
                    className="btn-download"
                    href={project.codeSource}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Télécharger le code source
                </a>
            )}

            {/* Vidéo de démonstration */}
            {project.demoVideo && (
                <div className="project-video">
                    <video controls>
                        <source src={project.demoVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}

        </>
    )}
        </div>
    );
}

export default ProjectDetails;
