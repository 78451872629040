import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';
import data from '../../data/data.json';

function Projects() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setProjects(data);
    }, []);

    return (
        <div className="projects">
            <h1>Projets</h1>
            <div className="projects-list">
            {projects.map(project => (
    <div key={project.id} className="project">
        <img
            src={project.image}
            alt={project.title}
            className={project.type.includes('Mobile') ? 'mobile' : ''}
        />
        <h2>{project.title}</h2>
        <h3>{project.type}</h3>
        <p>{project.shortDescription}</p>
        <Link to={`/project/${project.id}`}>
            <button className="btn-primaire">En savoir plus</button>
        </Link>
        {project.downloadLinks && (
    <div className="download-links">
        <a className="btn-download" href={project.downloadLinks.mac} target="_blank" rel="noopener noreferrer">
            Télécharger pour Mac
        </a>
        <a className="btn-download" href={project.downloadLinks.windows} target="_blank" rel="noopener noreferrer">
            Télécharger pour Windows
        </a>
    </div>
)}
        {project.codeSource && (
            <a className="btn-download" href={project.codeSource} target="_blank" rel="noopener noreferrer">
                Télécharger le code source
            </a>
        )}
    </div>
))}



            </div>
        </div>
    );
}

export default Projects;
